<template>
  <div>
    <div class="d-flex flex-row mb-2">
      <p class="text-2xl mb-2">Liste des objectifs</p>
      <v-autocomplete
        outlined
        dense
        v-model="objectiveTypeSelected"
        :items="objectiveTypes"
        :menu-props="{ maxHeight: '400', zIndex: 13 }"
        label="Type d'objectif"
        item-text="name"
        item-value="id"
        class="select-objective-type ml-16 shrink"
        @change="getData"
      ></v-autocomplete>
      <v-col class="text-right">
        <v-btn color="primary" link :to="{ name: 'premium-objectives-edit' }">
          <v-icon class="mr-3">{{ icons.mdiPencilBox }}</v-icon
          >Ajouter/Modifier des objectifs</v-btn
        >
      </v-col>
    </div>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="searchStr"
            append-icon="mdi-magnify"
            label="Recherche"
            @input="search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-card-text v-if="filteredData.length !== 0">
          <v-simple-table dense fixed-header height="500">
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="(value, key, index) in cols"
                    class="text-left"
                    :class="`field_${key}`"
                  >
                    {{ value }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in filteredData">
                  <td
                    v-for="(value, key, index) in cols"
                    class="cell"
                    :class="
                      index > 0 ? `value_${key} text-right` : `value_${key}`
                    "
                  >
                    <v-tooltip v-if="key in toolTips" top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          v-html="item[key]"
                        ></span>
                      </template>
                      <span>{{ toolTips[key][i] }}</span>
                    </v-tooltip>
                    <span v-else v-html="item[key]"></span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-text v-else>{{ noDataMessage }}</v-card-text>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import { mdiPencilBox } from "@mdi/js";
import axios from "@axios";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "ObjectiveList",
  components: {},
  data() {
    return {
      searchStr: "",
      noDataMessage: "",
      defaultNoDataMessage: "Retrieving data",
      objectiveTypeSelected: 0,
      objectiveTypes: [],
      filteredData: [],
      cols: {},
      toolTips: { site_name: [] },
      icons: {
        mdiPencilBox,
      },
    };
  },
  mounted() {
    saveRmraView(this.$route);
  },
  async created() {
    const { data } = await axios.get("/objectives/types/");
    this.objectiveTypes = data;
    this.objectiveTypeSelected =
      this.objectiveTypes.find((item) => item.name === "CA")?.id ?? 0;

    await this.getData();
  },
  methods: {
    async getData() {
      if (!this.objectiveTypeSelected) {
        return;
      }

      this.data = [];
      this.filteredData = [];
      for (const key of Object.entries(this.toolTips)) {
        this.toolTips[key] = []; // reset toolTips
      }
      this.noDataMessage = this.defaultNoDataMessage;

      const url = `/objectives/all-months-by-type/${this.objectiveTypeSelected}`;
      const { status, data } = await axios.get(url);
      if (status !== 200) {
        this.noDataMessage = "An error occurred.";
        return;
      }
      if (data.length == 0) {
        this.noDataMessage = "Empty data.";
        return;
      }

      for (let i = 0; i < data.length; i++) {
        this.toolTips["site_name"].push(data[i].site_domain);
        delete data[i].site_domain;
        delete data[i].type;
        delete data[i].site_id;
      }

      let cols = {
        site_name: "Site",
      };
      for (const key in data[0]) {
        if (!(key in cols)) {
          cols[key] = key;
        }
      }
      this.cols = cols;
      this.data = data;
      this.filteredData = data;
    },
    search() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      setTimeout(() => {
        if (this.searchStr == "") {
          this.filteredData = this.data;
          return;
        }
        const filteredData = [];
        const searchStrLC = this.searchStr.toLowerCase();
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i]["site_name"].toLowerCase().includes(searchStrLC)) {
            filteredData.push(this.data[i]);
          }
        }
        this.filteredData = filteredData;
      }, 500);
    },
  },
  watch: {},
};
</script>

<style scoped>
.field_site_name {
  width: 250px !important;
}
.value_site_name {
  position: sticky;
  left: 0;
  top: auto;
  z-index: 12;
  background-color: #fafafa !important;
}
</style>
